.main-content{
  max-height: 90%;
  overflow-y: scroll !important;
}

.sidebar {
  display: flex !important;
  overflow: hidden;
}

.lang-toggle-mashkor {
  bottom: 10 !important;
  flex: 4;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  padding: 0 10px;
}

.lang-toggle-mashkor p:first-of-type {
  font-weight: bold;
  margin: 10px 0;
}

.lang-toggle-mashkor span {
  padding: 3px;
  border-radius: 10px;
  color: #fff;
  cursor: pointer;
  align-self: flex-end;
  margin-bottom: 20px;
  background-color: #64df5d;
  width: 100%;
}

.lang-toggle-mashkor span p {
  margin: 0 !important;
  border: 2px #fff solid;
  border-radius: 10px;
  padding: 2px;
  text-align: center;
  font-size: 1.7rem;
}

.lang-toggle-mashkor span:hover{
  background-color: #00b800;
}

.lang-toggle {
  bottom: 10 !important;
  flex: 1;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
}

.lang-toggle p:first-of-type {
  font-weight: bold;
  margin: 10px 0;
}

.lang-toggle span {
  padding: 3px;
  border-radius: 0.28571429rem;
  color: #fff;
  cursor: pointer;
  align-self: flex-end;
  margin-bottom: 20px;
  background-color: #64df5d;
  width: 100%;
}

.lang-toggle span p {
  margin: 0 !important;
  border: 2px #fff solid;
  border-radius: 0.28571429rem;
  text-align: center;
  font-size: 1.7rem;
}

.lang-toggle span:hover{
  background-color: #00b800;
}

#version-num {
  margin: auto auto 8px auto;
  font-size: .9rem;
}
