.cursor-pointer {
  cursor: pointer;
}

.table-row-hover:hover {
  background-color: rgba(224, 224, 224, 0.5) !important;
}

.break-word {
  white-space: normal;
  word-wrap: break-word;
  overflow-wrap: break-word;
}
